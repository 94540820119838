import 'ui-theme/js/components/_drawer';

const menu = UX.menu;
const $body = $('body'),
    $window = $(window);
const tabKey = 9,
    escKey = 27;

menu.on.listPosition.scrollTo = function (menuElements, direction, value, timing = 0) {
    let secondary_menu = menuElements.sub.closest('.secondary-navigation').length > 0;
    let parentHeightVal =
            menu.listPositionVars.mainScrollingListHeight -
            (menuElements.parent.innerHeight() - menuElements.parent.height()), // height minus padding
        subHeightVal =
            menu.listPositionVars.mainScrollingListHeight -
            (menuElements.sub.innerHeight() - menuElements.sub.height()); // height minus padding
    if (direction === 'in') {
        if (secondary_menu) {
            menuElements.parent
                .find('.menu-parent .dropdown__menu')
                .css({'max-height': 0, height: 0, 'overflow-y': 'hidden', display: 'none'});

            menuElements.parent.attr('data-list-pos-corrected', 'true');
            menuElements.sub
                .css({'max-height': 'fit-content', height: 'fit-content', 'overflow-y': 'auto', display: 'block'})
                .attr('data-list-pos-corrected', 'true');
        } else {
            menuElements.parent
                .css({'max-height': parentHeightVal, height: parentHeightVal, 'overflow-y': 'hidden'})
                .attr('data-list-pos-corrected', 'true');
            menuElements.sub
                .css({'max-height': subHeightVal, height: subHeightVal, 'overflow-y': 'auto'})
                .attr('data-list-pos-corrected', 'true');
        }

        menuElements.parent.length &&
            menuElements.parent.animate(
                {
                    scrollTop: 0, // if we don't scroll the parent to the top, the sub will sometimes be cut off
                },
                {
                    duration: Math.abs(value - menuElements.parent.scrollTop()) > 125 ? 0 : timing,
                }
            );

        menuElements.sub.animate(
            {
                scrollTop: value,
            },
            {
                duration: Math.abs(value - menuElements.parent.scrollTop()) > 125 ? 0 : timing,
            }
        );
    } else if (direction === 'out') {
        if (secondary_menu) {
            menuElements.parent.attr('data-list-pos-corrected', 'true');
            menuElements.sub
                .css({'max-height': '', height: '', 'overflow-y': '', display: 'none'})
                .removeAttr('data-list-pos-corrected');
        } else {
            menuElements.parent.css({
                'max-height': parentHeightVal,
                height: parentHeightVal,
                'overflow-y': 'auto',
            });
            menuElements.sub
                .css({'max-height': '', height: '', 'overflow-y': ''})
                .removeAttr('data-list-pos-corrected');
        }

        menuElements.parent.length &&
            menuElements.parent.animate(
                {
                    scrollTop: value,
                },
                {
                    duration: Math.abs(value - menuElements.parent.scrollTop()) > 125 ? 0 : timing,
                }
            );
    }
};

menu.control = function () {
    $body.on('click', '[data-toggle="nav"]', function (e) {
        e.preventDefault();
        menu.$toggle = $(this);
        menu.$target = $('#' + menu.$toggle.data('target'));

        menu.items = menu.$target.find('a, button, input'); //$(".header__nav a, .header__nav button, .header__nav input");
        menu.items.each(function (index) {
            if (index === menu.items.length - 1) {
                menu.lastItem = $(this);
            }
        });

        menu.on.show(e);
    });

    $body.on('click', '.js--open[data-toggle="nav"]', function (e) {
        e.preventDefault();
        menu.on.hide(e);
    });

    $body.on('click', function (e) {
        if (!$(e.target).closest('.dropdown__toggle').length) {
            $('a.dropdown__toggle.hover').removeClass('hover');
        }
        if (
            !$(e.target).hasClass('icon-arrow_r') &&
            !$(e.target).parents('.main-nav__toggle__controller') &&
            !$(e.target).parents('.main-nav.menu--res').length &&
            $('.lock-screen[data-active="menu"]').length
        ) {
            menu.on.hide(e);
        }
    });

    $body.on('click', '.main-nav a', function (e) {
        if ($(this).attr('href') === '#') {
            e.preventDefault();
        }
    });

    $body.on('click', '[data-toggle="dropdown"]:not(.prev-items)', function (e) {
        e.preventDefault();
        menu.nestedMenu = $(this);
        menu.on.nested(e);
    });

    $body.on('click', '[data-toggle="dropdown"].prev-items', function (e) {
        e.preventDefault();
        menu.nestedMenu = $(this);
        menu.on.hide();
    });

    $body.on('click', '.menu-header', function (e) {
        e.preventDefault();

        if (menu.isMobile) {
            menu.on.hideNested(true);
        }
        e.stopPropagation(); // prevent closing menu when getting back from nested menu
    });
    $body.on('keydown', '.drawer__nav, [data-toggle="nav"]', function (e) {
        if (menu.isMobile) {
            if ((e.keyCode || e.which) === tabKey) {
                if (!menu.revers) {
                    menu.on.tab();
                } else {
                    menu.on.tabRevers();
                }
            }
        }
    });

    // without .get(0) e.elapsedTime and e.propertyName are undefined
    $body.get(0).addEventListener('transitionend', menu.on.listPosition.handleHeightSet);
    $window.on('resize', menu.on.listPosition.handleResize);

    $window.on('keyup', function (e) {
        menu.revers = !!(e.shiftKey && e.keyCode === tabKey);
        if (!menu.isMobile) {
            if ((e.keyCode || e.which) === tabKey) {
                menu.on.tabDesktop(e, menu.revers);
            }

            if ((e.keyCode || e.which) === escKey) {
                menu.on.escDesktop(e);
            }
        }
    });
};
